/* CSS for the profile class */

.profile {
    margin-top: 20px;
}

/* class sidebar inside of a profile class: make the bar gray */
.profile .sidebar {
    float: left;
    width: 200px;
    margin-left: 20px;
    margin-right: 10px;
    padding-top: 20px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

/* Make the image circular and centered */
.profile .sidebar .profile-image img {
    display: block;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    width: 160px;
    height: 160px;
}

/* Make the main content be at the right of the side bar */
/* Make the with be 100% minus 200px (the width of the sidebar) minus 3x20px (padding) */
.profile .main-content {
    float: right;
    margin-left: 10px;
    margin-right: 20px;

    width: calc(100% - 200px - 3*20px);
}
/* Gray background */

body {
  background-color: #111;
  color: #999;
}

.shade1 {
  background-color: #1111;
}

.shade2 {
  background-color: #222;
}

.shade3 {
  background-color: #d333;
}
/* For class message and role-user align to the right with a dark background color. */
.message.role-user {
  text-align: right;
  background-color: #333; /* Dark background color */
  color: #fff; /* White text color for contrast */
  padding: 10px;
  /* border-radius: 5px; */
  margin-bottom: 5px;
}

/* For class message and role-ai align to the left with a darker background color. */
.message.role-assistant {
  text-align: left;
  background-color: #222; /* Dark background color */
  color: #fff; /* White text color for contrast */
  padding: 10px;
  /* border-radius: 5px; */
  margin-bottom: 5px;
}

.session.active {
  background-color: #fff;
  /* padding: 10px; */
  border-radius: 5px;
  color: #222;
}
/* Make it look cool */
.nav {
  /* background-color: #f8f9fa; */
  height: 60px;
  /* display: flex; */
  justify-content: space-between;
  /* align-items: center; */
  /* padding: 0 50px; */
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.nav ul {
  list-style: none;
  display: flex;
}

.nav ul li {
  margin-left: 20px;
}

.nav ul li a {
  text-decoration: none;
  color: #AAA;
}
.nav .title {
  margin-left: 20px;
  text-decoration: none;
  color: #AAA;
}